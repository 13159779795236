/**
 * Toggle search in header
 */

document.addEventListener('DOMContentLoaded', (e) => {
    var search = document.querySelector('.c-search');
    var close = document.querySelector('.c-form-search__close');

    if (search) {
        search.addEventListener('click', toggleSearch);
    }

    if (close) {
        close.addEventListener('click', toggleSearch);
    }

    function toggleSearch(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        document.querySelector('.c-form-search').classList.toggle('c-form-search--hidden');
    }
});
