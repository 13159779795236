/**
 * Key deadlines
 */

document.addEventListener('DOMContentLoaded', () => {
   /*  Filtering */
   var keyDeadlines = document.querySelector('.c-key-deadlines');

   if (keyDeadlines) {
       var options = {
           valueNames: [ 'name' ],
           listClass: 'c-key-deadlines__list',
           searchClass: 'c-key-deadlines__search'
       };

       deadlines = new List("key-deadlines", options);

       var searchField = document.querySelector('.c-key-deadlines__search');

       searchField.onkeyup = function() {
           var searchString = searchField.value;

           if (deadlines.search) {
               deadlines.search(searchString);
           }
       };
   }
});
