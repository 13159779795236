/**
 * Show hidden 'read more' content
 */

document.addEventListener('DOMContentLoaded', (e) => {
    document.addEventListener('click', (e) => {
        const target = e.target;

        if (target.hasAttribute('readmore-target')) {
            var buttonTarget = target.getAttribute('readmore-target');

            // top
            document.querySelector('[content-excerpt=' + buttonTarget + ']').classList.remove('c-column__content--top');

            // existing
            document.querySelector('[content-remaining=' + buttonTarget + ']').classList.remove('c-column__content--hidden');

            // button
            document.querySelector('[readmore-target=' + buttonTarget + ']').classList.add('c-button--hidden');
        }
    });
});
