/**
 * Most lists
 */

document.addEventListener('DOMContentLoaded', () => {
    /* Filtering */
    var mostLists = document.querySelector('.c-most-lists');
    
    if (mostLists) {
        var options = {
            valueNames: [ 'name' ],
            listClass: 'c-most-lists__list',
            searchClass: 'c-most-lists__search'
        };

        let mostListLength = document.querySelector('.c-most-lists__list').getElementsByTagName("li").length;
        
        if (mostListLength > 0) {
            mostlistslist = new List("most-lists", options);

            var searchField = document.querySelector('.c-most-lists__search');

            searchField.onkeyup = function() {
                var searchString = searchField.value;

                if (mostlistslist.search) {
                    mostlistslist.search(searchString);
                }
            };
        }
    
    };
});
