/**
 * Custom selects
 */

document.addEventListener('DOMContentLoaded', () => {
    let customSelects = document.querySelectorAll('.select-wrapper');

    customSelects.forEach(element => {

        const select = element.querySelector('.select');

        element.addEventListener('click', () => {
            customSelects.forEach(element => {
                select.classList.remove('open');
            });

            select.classList.toggle('open');
        })
        
        for (const option of element.querySelectorAll(".custom-option")) {
            option.addEventListener('click', () => {
                if (!this.classList.contains('selected')) {
                    this.parentNode.querySelector('.custom-option.selected').classList.remove('selected');
                    this.classList.add('selected');
                    this.closest('.select').querySelector('.select__trigger span').textContent = this.textContent;
                }
            })
        }
        
        window.addEventListener('click', (e) => {
            if (!select.contains(e.target)) {
                select.classList.remove('open');
            }
        });
    });
});
