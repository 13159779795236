/**
 * Navigation
 */

document.addEventListener('DOMContentLoaded', () => {
    /* Open / Close navigation items */
    var body = document.querySelector('body');
    var hamburger = document.querySelector('.c-hamburger');

    if (hamburger) {
        hamburger.addEventListener('click', (e) => {
            e.preventDefault();
            body.classList.toggle('open-nav');
        });
    }

    /* Open / Close sub navigation items */
    var menuItemWithChildren = document.querySelectorAll('.menu-item-has-children > a');

    if (menuItemWithChildren) {
        menuItemWithChildren.forEach(element => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                e.target.closest('.menu-item-has-children').classList.toggle('menu-item-has-children-open')
            });
        });
    }

    /* Dummy link */
    document.addEventListener('click', (e) => {
        var target = e.target;
        if (target.closest('li') && target.closest('li').classList.contains('nav-dummy-link')) {
            e.preventDefault();
        }
    });
});
