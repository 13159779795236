/**
 * Most Viewed / Most Commented Tabs
 */

document.addEventListener('DOMContentLoaded', (e) => {
    document.addEventListener('click', (e) => {
        var target = e.target;

        if (target.hasAttribute('tab-target')) {
            var activeTargets = document.querySelectorAll('[tab-active]');

            for (var i = activeTargets.length - 1; i >= 0; i--) {
                activeTargets[i].removeAttribute('tab-active');
            }

            var toActivate = target.getAttribute('tab-target');
            var tabToActivate = document.querySelector('[tab-target="' + toActivate + '"]');
            var contentToActivate = document.querySelector('[content-target="' + toActivate + '"]');

            tabToActivate.setAttribute('tab-active', '');
            contentToActivate.setAttribute('tab-active', '');
        }
    });
});
